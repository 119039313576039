/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import cx from "classnames";

import { AppHeaderVisibility } from "enums/AppHeaderVisibility";

import styles from "./Layout.module.scss";
import { isDesktop, isMobile } from "react-device-detect";
import { AppHeader } from "../../components";
import dynamic from "next/dynamic";
import { useIsAuthenticated } from "../../hooks/useIsAuthenticated";
import { LayoutSection } from "components/LayoutSection/LayoutSection";

const AppFooter = dynamic(() => import("../../components/AppFooter"), {
  ssr: false,
});

export interface LayoutProps {
  children: React.ReactNode;
  className?: string;
  header?: AppHeaderVisibility;
  withElements?: boolean;
  config?: any;
}

const Layout = ({
  children,
  className,
  header,
  withElements = true,
  config,
}: LayoutProps) => {
  const { isLoggedIn, isChild } = useIsAuthenticated();
  return (
    <div className={cx(styles.container, className)}>
      {config && withElements ? (
        <AppHeader
          configuration={config}
          visibility={header}
          isLoggedIn={isLoggedIn}
          isChild={isChild}
        />
      ) : null}
      <div id="portal"></div>
      <LayoutSection>{children}</LayoutSection>
      {config && withElements ? (
        <AppFooter
          config={config}
          displayDesktopMenu={isDesktop}
          displayPhoneMenu={isMobile}
        />
      ) : null}
    </div>
  );
};

export default Layout;
