import React, { useEffect } from "react";

type LayoutSectionProps = {
  children: React.ReactNode;
  className?: string;
};

const DEFAULT_MIN_HEIGHT = 600;

export const LayoutSection: React.FC<LayoutSectionProps> = ({
  children,
  className,
}) => {
  const [style, setStyle] = React.useState({});

  useEffect(() => {
    if (window && window.innerHeight < DEFAULT_MIN_HEIGHT) {
      setStyle({ minHeight: window.innerHeight + 300 });
    }
  }, []);
  return (
    <section className={className} style={style} id="containerSection">
      <>{children}</>
    </section>
  );
};

export default LayoutSection;
